@use '@/styles/utils/mixins.scss' as *;
.footerMenuWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-top: 3rem;
  padding-bottom: 2rem;
  border-bottom: 0.063rem solid $primary;
  column-gap: 1.25rem;
  row-gap: 2rem;
  .footerMenuItem {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
  .footerMenuTitle {
    color: $dark;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.25rem 0;
    border-bottom: 0.063rem solid $primaryAlt;
    width: fit-content;
    display: flex;

    &:hover,
    &:focus,
    &:active {
      color: $primary;
    }
  }
  .subMenu {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 0.5rem;
    width: 100%;
  }
  .subLink {
    font-size: 1rem;
    padding: 0.25rem 0;
    color: $dark;
    display: flex;
    width: 100%;
    font-weight: normal;
    &:hover,
    &:focus,
    &:active {
      color: $primary;
    }
  }
}
